

















































/* eslint-disable */
import Vue from "vue";
export default Vue.extend({
  name: "Nav",
  data: () => ({
    expand: false,
    user: {
      admin: false,
      group: {
        permissions: [],
      },
    },
    items: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        link: "/",
        permission: "gerentor.dashboard",
      },
      {
        title: "Membros",
        icon: "mdi-card-account-details-outline",
        link: "/members",
        permission: "gerentor.person.customers.get_many",
      },
      {
        title: "Grupos/Células",
        icon: "mdi-google-maps",
        link: "/promise/cells",
        permission: "promise.cells.get_many",
      },
      {
        title: "Ministérios",
        icon: "mdi-playlist-music",
        link: "/promise/ministry",
        permission: "promise.cells.get_many",
      },
      {
        title: "Extrato Conta",
        icon: "mdi-chart-pie",
        link: "/finance/account-reports",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        title: "Financeiro",
        icon: "mdi-finance",
        link: "/finance/movements",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        title: "Contas a Pagar",
        icon: "mdi-currency-usd-off",
        link: "/finance/report/account-payables",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        title: "Projeção Entradas",
        icon: "mdi-currency-usd",
        link: "/finance/report/account-receivables",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        title: "Funcionários",
        icon: "mdi-account-group",
        link: "/base/employees",
        permission: "gerentor.person.employees.get_many",
      },

      {
        title: "Fornecedores",
        icon: "mdi-office-building-marker",
        link: "/base/suppliers",
        permission: "gerentor.suppliers.get_many",
      },
      {
        title: "Eventos",
        icon: "mdi-school",
        link: "/events",
        permission: "promise.events.get_many",
      },
      {
        title: "Patrimonio",
        icon: "mdi-chart-areaspline",
        link: "/patrimony",
        permission: "gerentor.products.get_many",
      },
    ],
  }),
  methods: {
    getUser() {
      let user = localStorage.getItem("user") as any;
      if (user) this.user = JSON.parse(user) as any;
    },
    validPermission(permission: any) {
      if (permission === "all") return true;
      if (!this.user) return false;
      if (this.user.admin) return true;
      const permissions = this.user?.group?.permissions?.map((d: any) => d.id);
      if (!permissions || !permissions?.length) return false;
      if (permissions.includes(permission) || (this.user && this.user.admin))
        return true;
      else return false;
    },
  },
  mounted() {
    this.getUser();
    this.$root.$on("changeDrawe", () => {
      this.expand = !this.expand;
    });
    const us = localStorage.getItem("user");
    if (us && typeof us === "string") {
      this.expand = JSON.parse(us).visibleMenu;
    }
  },
});
